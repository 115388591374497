html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.leaflet-container {
  height: 100%;
  background: #82db7a;

}
.map-container {
  height: 100%;
}
.info {
  position: absolute;
  top: 10px;
  z-index: 1000;
  width: 70%;
  max-width: calc(100vw - 100px);
  background: #f00;
  left: 50%;
  transform: translateX(-50%)
}

.my-map .leaflet-popup-content-wrapper {
  background:#1c7519;
  color:#f2f1f1;
  font-size:16px;
  line-height:24px;
  margin-top: 75px;
  }
.my-map .leaflet-popup-content-wrapper a {
  color:rgba(255,255,255,0.5);
  }
.my-map .leaflet-popup-tip-container {
  /*width:30px;*/
  /*height:15px;*/
  }
.my-map .leaflet-popup-tip {
  background:#1c7519;
  /*border-left:15px solid transparent;*/
  /*border-right:15px solid transparent;*/
  /*border-top:15px solid #2c3e50;*/
  }

/*bottom position expermiental*/
/*.my-map .leaflet-popup {*/
  /*top: 55px !important;*/
/*}*/

.my-map.swiping::after {
    content: 'Pomičite kartu pomoću dva prsta\A Use two fingers to move the map';
    white-space: pre;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    justify-content:center;
    display: flex;
    align-items: center;
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    pointer-events:none;
  }

.custom-icon {
  background: url(/img/custom-icon2.svg);
  background-size: contain;
  width: 40px;
  height: 40px;
  color: #F26C4F;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}


.item {
}
.item__author {
}
.item__title {
  font-weight: bold;
  margin-bottom: 10px;
}
.item__title a {
  color: #f2f1f1 !important;
  /*text-decoration-color: #999;*/
/*text-decoration-style: dotted;*/
}
.item__img, .item__audio {
  margin: 5px 0;
}
.item__img > img {
  width: auto;
  max-width: 180px;
  height: 120px;
}

.item__description {
  font-size: 13px;
}
.item__url {
  font-size: 13px;
}



.location-button {
  background-image: url(/img/gps_not_fixed-black-18dp.svg);
}
.location-button--error {
  background-image: url(/img/gps_off-black-18dp.svg);
}
.location-button--found {
  background-image: url(/img/gps_fixed-black-18dp.svg);
}
